import axios from 'axios'

export const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

/**
 * Registers the API client as a global property accessible via `this.$apiClient`.
 * @param {App<Element>} app
 */
export default function (app) {
  app.config.globalProperties.$apiClient = client
}
