import * as VueRouter from 'vue-router'

import authStore from '../stores/auth'

export const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We
  // are using the hash history for simplicity here.
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: '/:state?',
      props: true,
      name: 'ballot-access-map',
      // @ts-ignore
      component: () => import('../pages/BallotAccessMap.vue'),
    },
    {
      path: '/ballot-access-edit',
      name: 'ballot-access-edit',
      // @ts-ignore
      component: () => import('../pages/BallotAccessEdit.vue'),
    },
    {
      path: '/login',
      name: 'login',
      // @ts-ignore
      component: () => import('../pages/Login.vue'),
    },
  ],
})
router.beforeEach(async (to) => {
  const publicPages = ['/', '/login']
  const authRequired = !publicPages.includes(to.path)
  const auth = authStore()

  if (authRequired && !auth.user) {
    auth.returnUrl = to.fullPath
    return '/login'
  }
})

/**
 * Registers the Router with a Vue app.
 * @param {App<Element>} app
 */
export default function (app) {
  app.use(router)
}
