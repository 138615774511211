import * as Sentry from '@sentry/vue'
import { router } from './router.js'

/**
 * Initializes Sentry's browser integration.
 * @param {App} app
 */
export default (app) => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,

    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/cw24\.chrum\.me\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_SESSION_SAMPLE_RATE ?? 0.1,
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SAMPLE_RATE ?? 1.0,
  })
}
