import { createApp } from 'vue'
import App from './App.vue'
import apiClient from './plugins/api_client.js'
import pinia from './plugins/pinia.js'
import router from './plugins/router.js'
import sentry from './plugins/sentry.js'
import vuetify from './plugins/vuetify.js'

const app = createApp(App)

pinia(app)
apiClient(app)
router(app)
sentry(app)
vuetify(app)

app.mount('#app')
