import { defineStore } from 'pinia'

import { client } from '../plugins/api_client.js'
import { router } from '../plugins/router.js'

const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem('user')),
    returnUrl: null,
  }),
  actions: {
    async login(email, password) {
      const { data: user } = await client.post('/auth', { email, password })

      // update pinia state
      this.user = user

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user))
      client.defaults.headers.common['Authorization'] = `Bearer ${user.token.token}`

      // redirect to previous url or default to home page
      await router.push(this.returnUrl || '/')
    },
    async logout() {
      this.user = null
      localStorage.removeItem('user')
      await client.delete('/auth')
      client.defaults.headers.common['Authorization'] = null
      await router.push('/login')
    },
  },
})

export default useAuthStore
