<template>
  <v-app>
    <v-layout>
      <v-navigation-drawer
        expand-on-hover
        rail
      >
        <v-list density="compact" nav>
          <v-list-item prepend-icon="fa-solid fa-circle-half-stroke" title="Toggle light/dark theme"
                       @click="toggleTheme"/>
          <v-list-item prepend-icon="fa-solid fa-map-location-dot" title="Ballot Access Map"
                       :to="{ name: 'ballot-access-map' }"/>
          <div v-if="user">
            <v-list-item prepend-icon="fa-solid fa-pencil" title="Edit Ballot Access Data"
                         :to="{ name: 'ballot-access-edit' }"/>
            <v-list-item prepend-icon="fa-solid fa-user-slash" title="Logout" @click="logout"/>
          </div>
          <div v-else>
            <v-list-item prepend-icon="fa-solid fa-user-lock" title="Login" :to="{ name: 'login' }"/>
          </div>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container>
          <router-view/>
        </v-container>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
import { useTheme } from 'vuetify'
import useAuthStore from './stores/auth.js'

export default {
  setup() {
    return {
      authStore: useAuthStore(),
      theme: useTheme(),
    }
  },
  data() {
    return {
      drawer: true,
      rail: true,
    }
  },
  computed: {
    user() {
      return this.authStore.user
    }
  },
  methods: {
    async logout() {
      await this.authStore.logout()
    },
    setDarkTheme() {
      this.theme.global.name.value = 'dark'
    },
    toggleTheme() {
      this.theme.global.name.value = this.theme.global.current.value.dark ? 'light' : 'dark'
    },
  },
  mounted() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.setDarkTheme()
    }
  },
}
</script>
